import {
  BaseQueryApi,
  BaseQueryError,
  BaseQueryExtraOptions,
  BaseQueryFn,
  QueryReturnValue,
} from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { nanoid } from 'nanoid';

type TOnError = <ErrResultType>(error: ErrResultType) => string;

type TQueryFn<QueryArg, BaseQuery extends BaseQueryFn, ResultType> = {
  (
    arg: QueryArg,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQuery>,
    baseQuery: (arg: Parameters<BaseQuery>[0]) => ReturnType<BaseQuery>,
  ): Promise<QueryReturnValue<ResultType, BaseQueryError<BaseQuery>>>;
};

interface IOnNotificationProps {
  api: BaseQueryApi;
  error: unknown;
  uuid: string;
  onError?: TOnError;
}

interface IOptions {
  showNotificationOnError?: boolean;
}

interface IQueryFnWrapperProps {
  onNotification: (props: IOnNotificationProps) => void;
}

export const queryFnWrapper =
  ({ onNotification }: IQueryFnWrapperProps) =>
  <QueryArg, BaseQuery extends BaseQueryFn, ResultType>(
    queryFn: TQueryFn<QueryArg, BaseQuery, ResultType>,
    onError?: TOnError,
    options: IOptions = {
      showNotificationOnError: true,
    },
  ) =>
  async (
    arg: QueryArg,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQuery>,
    baseQuery: (arg: Parameters<BaseQuery>[0]) => ReturnType<BaseQuery>,
  ): Promise<QueryReturnValue<ResultType, BaseQueryError<BaseQuery>>> => {
    try {
      return await queryFn(arg, api, extraOptions, baseQuery);
    } catch (error) {
      if (options.showNotificationOnError) {
        onNotification({
          api,
          error,
          uuid: nanoid(),
          onError,
        });
      }

      return {
        error,
      };
    }
  };
